import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Reflector } from "three/examples/jsm/objects/Reflector";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const scene = new THREE.Scene();

const renderer = new THREE.WebGLRenderer();
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.shadowMap.needsUpdate = true;
renderer.setSize(window.innerWidth, window.innerHeight);

const camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  1,
  500
);
camera.position.set(50, 15, 100);
camera.lookAt(0, 0, 0);

const loader = new FontLoader();

const material = new THREE.MeshPhysicalMaterial({ color: 0x55cc55 });
material.wireframe = false;

loader.load("./static/font.json", function (font) {
  const geometry = new TextGeometry("Green Inc.", {
    font: font,
    size: 15,
    height: 5,
    curveSegments: 12,
    bevelEnabled: true,
    bevelThickness: 0,
    bevelSize: 0,
    bevelOffset: 0,
    bevelSegments: 2,
  });
  geometry.center();
  geometry.normalizeNormals();
  const text = new THREE.Mesh(geometry, material);
  text.castShadow = true;
  scene.add(text);
});

let mixer: THREE.AnimationMixer;
const modelLoader = new GLTFLoader();

modelLoader.load(
  "./static/scene006.glb",
  async function (gltf) {
    gltf.scene.scale.set(5, 5, 5);
    gltf.scene.position.set(0, -8, -30);
    gltf.scene.rotation.set(0, 0, 0);

    mixer = new THREE.AnimationMixer(gltf.scene);
    const animationAction = mixer.clipAction((gltf as any).animations[0]);
    animationAction.play();

    gltf.scene.traverse(function (node) {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
        // node.material.wireframe = true;
      }
    });
    scene.add(gltf.scene);
  },
  undefined,
  function (error) {
    console.error(error);
  }
);

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
directionalLight.castShadow = true;
directionalLight.position.set(10, 30, 50);
directionalLight.shadow.mapSize.width = 2048; // default
directionalLight.shadow.mapSize.height = 2048; // default
directionalLight.shadow.camera.near = 0.1; // default
directionalLight.shadow.camera.far = 500; // default
directionalLight.shadow.camera.top = -100; // default
directionalLight.shadow.camera.right = 100; // default
directionalLight.shadow.camera.left = -100; // default
directionalLight.shadow.camera.bottom = 100; // default
scene.add(directionalLight);

const light = new THREE.AmbientLight(0xffffff, 0.2);
scene.add(light);

const floorG = new THREE.PlaneGeometry(1000, 1000);
const plane = new Reflector(floorG, {
  clipBias: 0.003,
  textureWidth: window.innerWidth * window.devicePixelRatio,
  textureHeight: window.innerHeight * window.devicePixelRatio,
  color: 0x111111,
});
plane.rotateX(-Math.PI / 2);
plane.position.set(0, -7.5, 0);
scene.add(plane);

const controls = new OrbitControls(camera, renderer.domElement);
controls.target.set(0, 3, 0);
controls.autoRotate = true;
controls.autoRotateSpeed = 0.15;
controls.maxPolarAngle = Math.PI / 2;
controls.maxDistance = 300;
controls.minDistance = 50;
controls.enableDamping = true;
controls.dampingFactor = 0.01;

const clock = new THREE.Clock();

window.addEventListener("resize", function () {
  renderer.setSize(window.innerWidth, window.innerHeight);
  camera.aspect = window.innerWidth / window.innerHeight;
});
window.addEventListener("DOMContentLoaded", function () {
  document.querySelector("#three-js")!.appendChild(renderer.domElement);
  function animate() {
    requestAnimationFrame(animate);
    controls.update();
    if (mixer) mixer.update(clock.getDelta());
    renderer.render(scene, camera);
    camera.updateProjectionMatrix();
  }
  animate();
});
